import { Link, graphql } from 'gatsby'
import { getSrc, GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import * as s from '~/components/pages/blog/blog.module.scss'
import { Seo, SnsShare, TagList, RelatedPost } from '~/components'
import Arrow from '~/images/common_icon_arrow_r.svg'

const BlogPostTemplate = ({ data: { post } }) => {
  const featuredImage = {
    image:
      post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  }
  const featuredImagePath = getSrc(featuredImage.image)
  const category = {
    name: post.categories?.nodes[0]?.name,
    uri: post.categories?.nodes[0]?.uri,
    slug: post.categories?.nodes[0]?.slug,
  }
  const tags = post.tags?.nodes
  const categories = post.categories?.nodes
  const author = {
    name: post.author?.node?.name,
  }

  return (
    <>
      <Seo
        title={post.title}
        pageUrl={'blog/' + post.id}
        ogp={featuredImage ? 'https://snish.jp' + featuredImagePath : null}
      />

      <div className="blog">
        <article
          className={s.article}
          itemScope
          itemType="http://schema.org/Article"
        >
          <header className={s.articleHeader}>
            <div className={s.articleHeaderDetail}>
              <div className="contentSubComponent">
                <p className={s.articleCategory}>
                  <Link to={`/category/${category.slug}`}>{category.name}</Link>
                </p>
                <small className={s.articleDate + ' heading'}>
                  {post.date} UP
                </small>
                <h1
                  itemProp="headline"
                  className={s.articleTitle + ' heading is-primary'}
                >
                  <span itemProp="headline">{parse(post.title)}</span>
                </h1>
                <p className={s.articleAuthor + ' heading'}>
                  STAFF / <span>{author.name}</span>
                </p>
              </div>
            </div>
            <div className={s.thumbnail}>
              {featuredImage?.image ? (
                <GatsbyImage
                  image={featuredImage.image}
                  alt={featuredImage.alt}
                />
              ) : (
                <StaticImage
                  src="../images/top_mv_pc.jpg"
                  alt=""
                  placeholder="blurred"
                  layout="fullWidth"
                />
              )}
            </div>
          </header>

          <nav className="contentComponent">
            <ul className="breadcrumbComponent mincho">
              <li>
                <Link to="/" itemProp="url">
                  TOP
                </Link>{' '}
                <span>
                  <Arrow />
                </span>
              </li>
              <li>
                <Link to="/blog/" itemProp="url">
                  BLOG
                </Link>{' '}
                <span>
                  <Arrow />
                </span>
              </li>
              <li>{post.title}</li>
            </ul>
          </nav>

          {!!post.content && (
            <section itemProp="articleBody" className="contentSubComponent">
              <div
                className={s.articleBody}
                dangerouslySetInnerHTML={{
                  __html: post.content,
                }}
              />
            </section>
          )}

          <footer className={s.articleFooter}>
            <div className="contentSubComponent">
              {categories.length > 0 && (
                <dl className="tagListComponent mincho">
                  <dt className="heading">CATEGORY</dt>
                  <TagList type={'category'} tags={categories} />
                </dl>
              )}
              {tags.length > 0 && (
                <dl className="tagListComponent mincho">
                  <dt className="heading">TAG</dt>
                  <TagList type={'tag'} tags={tags} />
                </dl>
              )}
              <div className="shareIcons is-bk">
                <dl className={s.articleShare}>
                  <dt className="heading">Share</dt>
                  <SnsShare
                    title={post.title}
                    link={`https://snish.jp/blog/${post.slug}/`}
                  />
                </dl>
              </div>
              <Link className={s.articleBackLink} to="/blog/">
                <span>Back</span>
              </Link>
            </div>
          </footer>
        </article>

        <section className={s.relatedPostList}>
          <header className="subTitleComponent">
            <h2 className="heading pageTitle">
              <span>関連記事</span>RECOMMEND
            </h2>
          </header>
          <div className="contentComponent">
            <RelatedPost slug={category.slug} />
          </div>
        </section>
      </div>
    </>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById($id: String!) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      slug
      tags {
        nodes {
          uri
          name
          slug
        }
      }
      categories {
        nodes {
          name
          uri
          slug
        }
      }
      author {
        node {
          name
        }
      }
      date(formatString: "YYYY-MM-DD")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
  }
`
